import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import { useDispatch } from "react-redux";
import { goToNextStep } from "../features/journey";
import Spacer from "../common/components/Spacer";
import HalfSpacer from "./HalfSpacer";
import BouncingAnchor from "../common/components/BouncingAnchor";
import Button from "../common/components/Button";

const InPersonLandAcknowledgement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="land-acknowledgement-1">
      <VerticalCenter>
        <Center>
          <Padding>
            <img
              src="assets/images/landaknow_1_trees.svg"
              alt={t(
                "An icon of three coniferous trees in a triangle formation"
              )}
              width="20%"
            />
          </Padding>
          <Padding>
            <h3>
              {t(
                "Remixed intersects with audiences, story contributors, and communities that reside on Indigenous territories across Turtle Island."
              )}
            </h3>
          </Padding>
          <Padding>
            <h3>
              {t(
                "As visitors on this land, we honour the Indigenous caretakers who continue to hold relationships with the land and waterways since time immemorial."
              )}
            </h3>
          </Padding>
          <Padding>
            <h3>
              {t(
                "We consider our role in reconciliation and invite you to do the same."
              )}
            </h3>
          </Padding>
          <BouncingAnchor anchor="infinity" />
        </Center>
      </VerticalCenter>
      {/* section 2 */}
      <Spacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_2_infinity.svg"
            alt={t(
              "An infinity symbol icon representing relationships and interdependence"
            )}
            width="20%"
            id="infinity"
          />
          <Padding>
            <h3>
              {t(
                "Climate change shows that our actions are causing negative impacts to the Earth, resulting in imbalance."
              )}
            </h3>
          </Padding>
          <Padding>
            <h3>
              {t(
                "Creating a respectful relationship to the land facilitates the change that is necessary to build a sustainable future."
              )}
            </h3>
          </Padding>
          <BouncingAnchor anchor="hands" />
        </Center>
      </VerticalCenter>
      {/* section 3 */}
      <Spacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_3_hands.svg"
            alt={t(
              "An outline of two hands cupped around two leaves that emerge out the top of the icon"
            )}
            width="20%"
            id="hands"
          />
          <Padding>
            <h3>
              {t(
                "Listen to Remixed story contributor Eddy Charlie sharing about his relationship to Duncan Quw t’sun, the warm lands, and how a walk with his grandfather on this land shaped how he makes change."
              )}
            </h3>
          </Padding>
        </Center>
      </VerticalCenter>
      <HalfSpacer />
      <VerticalCenter>
        <Padding>
          <Button
            onClick={() => {
              dispatch(goToNextStep());
            }}
          >
            ▶ {t("Play")}
          </Button>
        </Padding>
      </VerticalCenter>
    </div>
  );
};

export default InPersonLandAcknowledgement;
