import useSystemAudio from "./useSystemAudio";

interface TileProps {
  children: React.ReactNode;
  label: string;
  onClick: () => void;
  isSelected: boolean;
}

export function Tile(props: TileProps) {
  const { click } = useSystemAudio();

  return (
    <div
      className="tile-wrapper"
      onClick={() => {
        click.play();
        props.onClick();
      }}
    >
      <div
        className="tile"
        style={props.isSelected ? { border: "var(--border-thin-active)" } : {}}
      >
        {props.children}
      </div>
      <p>{props.label}</p>
    </div>
  );
}

interface TilesProps {
  children: React.ReactNode;
}

export function Tiler(props: TilesProps) {
  return <div className="tiles-wrapper">{props.children}</div>;
}
