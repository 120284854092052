import { useDispatch } from "react-redux";
import { resumeJourney } from "../features/journey";
import * as LocalStoragePersister from "../common/LocalStoragePersister";
import { resumeLibrary } from "../features/acoustics/AudioLibraryReducer";
import { resumeListenerProfile } from "../features/listenerProfile";
import VerticalCenter from "../common/components/VerticalCenter";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Padding from "../common/components/Padding";
import Center from "../common/components/Center";
import { resumeApplication } from "../features/application/ApplicationReducer";

const COUNTDOWN_TO_CONTINUE_SEC = 10;

const TakeABreath = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [secondsLeft, setSecondsLeft] = useState(COUNTDOWN_TO_CONTINUE_SEC);

  const onContinue = () => {
    dispatch(resumeJourney(LocalStoragePersister.previousJourney()));
    dispatch(resumeLibrary(LocalStoragePersister.previousAudioLibrary()));
    dispatch(
      resumeListenerProfile(LocalStoragePersister.previousListenerProfile())
    );
  };

  useEffect(() => {
    dispatch(resumeApplication(LocalStoragePersister.previousApplication()));
  }, []);

  useEffect(() => {
    LocalStoragePersister.removeStallFalg();
    setSecondsLeft(COUNTDOWN_TO_CONTINUE_SEC);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setSecondsLeft((oldSeconds) => oldSeconds - 1);
    }, 1000);

    return () => {
      setSecondsLeft(COUNTDOWN_TO_CONTINUE_SEC);
      clearInterval(timerId);
    };
  }, []);

  // Move to next step when countdown expires
  useEffect(() => {
    if (secondsLeft <= -1) {
      onContinue();
    }
  }, [secondsLeft]);

  return (
    <VerticalCenter>
      <Center>
        <Padding>
          <h1>{t("Let's take a breath before we continue.")}</h1>
        </Padding>

        <Padding>
          <h1>{secondsLeft}</h1>
        </Padding>
      </Center>
    </VerticalCenter>
  );
};

export default TakeABreath;
