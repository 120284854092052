import { use } from "i18next";
import {
  FirstQuestionnaireAudioSourceUrls,
  SecondQuestionnaireAudioSourceUrls,
  ThirdQuestionnaireAudioSourceUrls,
  IntroAudioSourceUrls,
} from ".";
import { BASE_URL } from "../BaseProxy";

const AUDIO_URL = `${BASE_URL}/getAudio`;
const username = "remixed_app";
const password = "GuillaumeTheGreat";
const authString = `${username}:${password}`;

const headers = { authorization: `Basic ${btoa(authString)}` };

const toURL = (useAtHomeAudio: boolean): string => {
  if (!useAtHomeAudio) {
    return `${AUDIO_URL}/inPerson`;
  }

  return `${AUDIO_URL}`;
};

export interface FirstListenerProfileSegments {
  userID: number;
  showID: string;
  selection: number;
  soothingSound: number;
  musicOld: number;
  exclusion: string;
}

export interface SecondListenerProfileSegments {
  userID: number;
  showID: string;
  listening: 1; // Can't determine if this value is used
  enneagram: number;
  attachment: number;
  proChange: number;
  changeImpact: number;
  exclusion: string;
  changeExperience: number;
}

export interface ThridListenerProfileSegments {
  userID: number;
  showID: string;
  musicNew: number;
}

export interface IntroListenerProfileSegements {
  showID: string;
}

export interface IntroAudioSegments {
  showID: string;
  useAtHomeAudio: boolean;
  device: string;
  browser: string;
}

export const getIntroAudio = (
  segments: IntroAudioSegments
): Promise<IntroAudioSourceUrls> => {
  console.log(segments);
  const formData = new FormData();

  // @ts-ignore
  Object.keys(segments).map((key: any) => formData.append(key, segments[key]));

  return fetch(`${toURL(segments.useAtHomeAudio)}/intro/`, {
    method: "POST",
    mode: "cors",
    headers,
    body: formData,
  }).then(
    // @ts-ignore
    (response): IntroAudioSourceUrls => response.json()
  );
};

export const getFirstAudio = (
  segments: FirstListenerProfileSegments,
  useAtHomeAudio: boolean
): Promise<FirstQuestionnaireAudioSourceUrls> => {
  const formData = new FormData();

  // @ts-ignore
  Object.keys(segments).map((key: any) => formData.append(key, segments[key]));

  return fetch(`${toURL(useAtHomeAudio)}/firstAudio/`, {
    method: "POST",
    mode: "cors",
    headers,
    body: formData,
  }).then(
    // @ts-ignore
    (response): FirstQuestionnaireAudioSourceUrls => response.json()
  );
};

export const getSecondAudio = (
  segments: SecondListenerProfileSegments,
  useAtHomeAudio: boolean
): Promise<SecondQuestionnaireAudioSourceUrls> => {
  const formData = new FormData();
  // @ts-ignore
  Object.keys(segments).map((key: any) => formData.append(key, segments[key]));

  return fetch(`${toURL(useAtHomeAudio)}/secondAudio/`, {
    method: "POST",
    mode: "cors",
    headers,
    body: formData,
  }).then(
    // @ts-ignore
    (response): SecondQuestionnaireAudioSourceUrls => response.json()
  );
};

export const getThridAudio = (
  segments: ThridListenerProfileSegments,
  useAtHomeAudio: boolean
): Promise<ThirdQuestionnaireAudioSourceUrls> => {
  const formData = new FormData();

  Object.keys(segments).map((key: any) => {
    if (key === "userID") {
      formData.append("userID", "46508");
    } else {
      // @ts-ignore
      formData.append(`${key}`, `${segments[key]}`);
    }
  });

  return fetch(`${toURL(useAtHomeAudio)}/lastAudio/`, {
    method: "POST",
    mode: "cors",
    headers,
    body: formData,
  }).then(
    // @ts-ignore
    (response): ThirdQuestionnaireAudioSourceUrls => response.json()
  );
};
