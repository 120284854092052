import { useTranslation } from "react-i18next";
import { selectUserId } from "../features/listenerProfile/select";
import { RootState } from "../app/Store";
import { useStore } from "react-redux";

import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import Dropdown from "../common/components/Dropdown";
import Stack from "../common/components/Stack";
import Button from "../common/components/Button";

const End = () => {
  const { t } = useTranslation();
  const state = useStore().getState() as RootState;
  const userId = selectUserId(state);

  return (
    <>
      <Center>
        <h1>{t("Remixed")}</h1>
      </Center>
      <Padding>
        <Center>
          <Stack>
            <h2>
              {t(
                "Thank you for experiencing Remixed with us. We’ve prepared a quick survey to help capture your feedback about the performance (anonymously if preferred). We ask that you include your playlist number:"
              )}
            </h2>
            <h1 style={{ letterSpacing: "6px" }}>
              <strong>{userId}</strong>
            </h1>
            <Center>
              <Button
                style={{ letterSpacing: "var(--size-1)" }}
                onClick={() => {
                  window.open(
                    "https://forms.gle/HbCBtdgczfmRqEhz9",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                {t("GO TO FEEDBACK FORM")}
              </Button>
            </Center>
          </Stack>
        </Center>
      </Padding>
      <VerticalCenter>
        <Center>
          <Dropdown
            title={<h3>About Remixed</h3>}
            content={
              <>
                <p>
                  Change is complex: beautiful and relentless, nourishing and
                  destructive; invigorating and exhausting.
                </p>
                <p>
                  Remixed is a deep listening experience that invites collective
                  contemplation on how we make change in our lives, communities,
                  and world. Remixed may be your starting place, resting place,
                  or place to conclude – it’s up to you.
                </p>
                <p>
                  During the performance, our algorithm gets to know you: how
                  you feel about change, what you like to listen to, and more.
                  Your personalized playlist is then revealed. Listen in to
                  music, narrative prompts, and true stories of transformation
                  from around the globe. Everything is chosen just for you.
                </p>
                <p>
                  This site is your portal into the performance. You will
                  receive an access code that brings you inside. If you are
                  experiencing our show in-person, our team will provide the
                  code upon your arrival. If you’re experiencing the show
                  remotely, you will receive the code by text.
                </p>
                <p>Let’s listen to the sound of change and plant some seeds!</p>
              </>
            }
          />
          <Dropdown
            title={<h3>Know Before You Go</h3>}
            content={
              <>
                <p>
                  Remixed is approximately 40 minutes long, but it may vary
                  slightly depending on your choices.
                </p>
                <p>
                  Bring a charged smartphone and headphones for use during the
                  performance. Wifi is provided.
                </p>
                <p>
                  Our device guide (LINK) identifies compatible devices and how
                  to set your phone up. It’s easy, but takes a moment or two,
                  especially if you have an older phone.
                </p>
                <p>
                  If you have questions, email{" "}
                  <a href="mailto:info@thisistrophy.com">
                    info@thisistrophy.com
                  </a>{" "}
                  or speak to our team before the show.
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Accessibility</h3>}
            content={
              <>
                <p>
                  Let’s create the conditions you need to listen and change.
                  Remixed has a set of options to personalize your experience.
                  For example, you have control over text size and contrast,
                  access to alt text for images, and the option of moving around
                  as you need to during the performance.
                </p>
                <p>
                  Learn more about Remixed and our accessibility features in our
                  access guide:{" "}
                  <a
                    href="https://docs.google.com/document/d/1e7b_kLRh4V3EEpUZfgl7Hc40u_I30NFBa7A_SrEB9Qo/edit#heading=h.w056bso8q8io"
                    target="_blank"
                  >
                    click here
                  </a>
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Who we are</h3>}
            content={
              <>
                <p>
                  Remixed was created by the following artistic team in
                  collaboration with change-makers and community members across
                  Canada and beyond.
                </p>
                <ul>
                  <li>Director and Producer: Sarah Conn</li>
                  <li>Dramaturg and Collaborator: Laurel Green</li>
                  <li>
                    Environmental Installation Concept and Flower Installation
                    Designer: Allison O’Connor
                  </li>
                  <li>
                    Projection & Cloud Installation Designer: Michaela van
                    Beinum
                  </li>
                  <li>Lighting Designer: Guillaume Saindon</li>
                  <li>Assistant Lighting Designer: Alexis Gagnon-Clément </li>
                  <li>Electrical Designer: Michaela van Beinum</li>
                  <li>UI/UX Designer: Véronique Claude</li>
                  <li>Associate UI/UX Designer: Frankie Latour</li>
                  <li>Web Developers: Kieran Dunch, Miceal Gallagher</li>
                  <li>Backend Developer: Julien Desautels</li>
                  <li>
                    In-App and Performance Sound Designer and Collaborator:
                    Nancy Tam
                  </li>
                  <li>Assistant Sound Designer: Charlier Cooper</li>
                  <li>Decade Music Curation: AL Connors</li>
                  <li>
                    Creative Makers: Robert Chartrand, Kris Van Loon, Madison
                    O’Connor, Robert O’Connor
                  </li>
                  <li>Accessibility Dramaturg: Shay Erlich</li>
                  <li>UX Consultants: Hosan Lee, christian scott</li>
                </ul>
                <p>
                  Our story contributors are anonymous to support their
                  participation. They live all over the globe, and include
                  community activists, doctors, outreach workers, parents,
                  children, death doulas, artists, CEOs, tarot card readers,
                  apiarists, urban planners, military officers, small busines
                  owners, an anesthesiologist, and several opera singers. They
                  might even include you! We are so grateful for their
                  generosity and courage. If you’d like to contribute a story to
                  our archive, get in touch at this link{" "}
                  <a
                    target="_blank"
                    href="https://www.thisistrophy.com/get-involved"
                  >
                    click here
                  </a>
                  .
                </p>
                <p>
                  Remixed is being developed thanks to the incredible support of
                  ArtEngine, In the Soil Arts Festival, the undercurrents
                  festival’s under development program, the City of Ottawa,
                  Ontario Arts Council, Canada Council for the Arts, and the
                  Ottawa Seed Library.
                </p>
                <p>
                  Our story gathering partners include the Parkdale Food Centre,
                  Wellspring Cancer Support Centre, Orange Shirt Day Victoria,
                  and Calgary’s Centre for Newcomers.
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Planting guide</h3>}
            content={
              <>
                <p>
                  We are part of ecosystems in constant change, and experiencing
                  change is a way to feel our bodies connecting to these
                  systems.
                </p>
                <p>
                  At one point in the experience, you will be invited to plant a
                  seed. The seeds are delicious microgreens that are great for
                  salads, smoothies or garnishes. Give them water and keep in
                  the dark for a few days and they’ll sprout. Share photos of
                  your plants by tagging us on social media{" "}
                  <a href="https://www.instagram.com/thisistrophy/">
                    @thisistrophy
                  </a>
                  . We love photos of baby plants!
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Contact us</h3>}
            content={
              <>
                <p>
                  If you’d like to know more about the show, email us at{" "}
                  <a href="info@thisistrophy.com">info@thisistrophy.com</a>.
                </p>
              </>
            }
          />
        </Center>
      </VerticalCenter>
    </>
  );
};
export default End;
