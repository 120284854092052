import { useDispatch } from "react-redux";
import { wantThreeThings } from "../features/listenerProfile";
import { useTranslation } from "react-i18next";

import Padding from "../common/components/Padding";
import PillMultiSelect from "../common/components/PillMultiSelect";
import Center from "../common/components/Center";

export default function Question2WantThreeThings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const DESIRES = [
    "Be at peace & bring harmony",
    "Be valued & successful",
    "Feel knowledgeable & competent",
    "Express creatively & be seen as special",
    "Improve myself & the world",
    "Know that I'm helpful & loved",
    "Be truthful & in charge",
    "Know that I'm safe & supported",
    "Feel good & carefree",
  ];

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("What do you want more than anything else today?")}</h1>
        </Center>
        <Center>
          <Padding>
            <pre>{t("SELECT UP TO TWO")}</pre>
          </Padding>
        </Center>
      </Padding>
      <Padding>
        <PillMultiSelect
          maxNumSelectable={2}
          options={DESIRES.map((desire) => t(desire.toLocaleUpperCase()))}
          onClick={(valuesList) => dispatch(wantThreeThings(valuesList))}
        />
      </Padding>
    </>
  );
}
