import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

const Goodbye = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <h1>{t("Goodbye")}</h1>
      </Center>
    </VerticalCenter>
  );
};
export default Goodbye;
