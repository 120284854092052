import { useStore } from "react-redux";
import { selectAudioLibrary } from "../../features/acoustics/select";
import { AudioName, Status } from "../../features/acoustics";
import { RootState } from "../../app/Store";
import { useEffect } from "react";

import VerticalCenter from "./VerticalCenter";
import Center from "./Center";
import Padding from "./Padding";

import gsap from "gsap";

interface AudioStatusProps {
  audioNames: AudioName[];
}

const AudioLoadingStatus = (props: AudioStatusProps) => {
  const state = useStore().getState() as RootState;
  const audioLibrary = selectAudioLibrary(state);

  const { audioNames } = props;
  const showOverlayStatus: Status[] = ["LOADING", "NOT_LOADED"];
  const audios = audioNames.map((audioName) => audioLibrary[audioName]);
  const audioStatus = audios.filter((audio) =>
    showOverlayStatus.includes(audio.status)
  );
  const showOverlay = audioStatus.length > 0;

  useEffect(() => {
    const notesTl = gsap.timeline({ repeat: -1 });
    notesTl.to("#notes", 5, { x: 32 });
  }, [audios]);

  if (showOverlay) {
    return (
      <div
        className="audioLoading"
        style={{
          display: showOverlay ? "" : "none",
        }}
      >
        <VerticalCenter>
          <Padding>
            <Center>
              <svg
                viewBox="0 0 392 166"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs></defs>
                <g id="Page-1" stroke="none" fill="none">
                  <g
                    id="Desktop-Copy-4"
                    transform="translate(-4.000000, -117.000000)"
                  >
                    <circle
                      id="outline"
                      stroke="var(--surface-1)"
                      cx="200.5"
                      cy="200.5"
                      r="66.5"
                    ></circle>
                    <g
                      id="staff"
                      transform="translate(134.000000, 180.000000)"
                      stroke="var(--surface-1)"
                    >
                      <path d="M0.25,20 L133,20" id="Line"></path>
                      <path
                        d="M0.745640086,10.5 L131.921126,10.5"
                        id="Line-Copy"
                      ></path>
                      <path d="M3.21439994,1 L129.5,1" id="Line-Copy-4"></path>
                      <path
                        d="M0.745640086,29.5 L131.921126,29.5"
                        id="Line-Copy-2"
                      ></path>
                      <path
                        d="M2.73813331,39 L129.90489,39"
                        id="Line-Copy-3"
                      ></path>
                    </g>
                    <g id="notes" transform="translate(269.500000, 176.500000)">
                      <g id="Group" transform="translate(0.000000, 19.500000)">
                        <ellipse
                          id="Oval-3"
                          fill="var(--surface-1)"
                          transform="translate(7.287431, 22.682819) rotate(-25.000000) translate(-7.287431, -22.682819) "
                          cx="7.28743137"
                          cy="22.6828195"
                          rx="5.6778451"
                          ry="4.22262892"
                        ></ellipse>
                        <path
                          d="M11.75,22.25 L11.75,0.25"
                          id="Line-2"
                          stroke="var(--surface-1)"
                        ></path>
                      </g>
                      <g
                        id="Group-Copy"
                        transform="translate(36.500000, 0.000000)"
                      >
                        <ellipse
                          id="Oval-3"
                          fill="var(--surface-1)"
                          transform="translate(7.287431, 22.682819) rotate(-25.000000) translate(-7.287431, -22.682819) "
                          cx="7.28743137"
                          cy="22.6828195"
                          rx="5.6778451"
                          ry="4.22262892"
                        ></ellipse>
                        <path
                          d="M11.75,22.25 L11.75,0.25"
                          id="Line-2"
                          stroke="var(--surface-1)"
                        ></path>
                      </g>
                      <g
                        id="Group-2"
                        transform="translate(74.000000, 9.500000)"
                      >
                        <g id="Group-Copy-2">
                          <ellipse
                            id="Oval-3"
                            fill="var(--surface-1)"
                            transform="translate(7.287431, 22.682819) rotate(-25.000000) translate(-7.287431, -22.682819) "
                            cx="7.28743137"
                            cy="22.6828195"
                            rx="5.6778451"
                            ry="4.22262892"
                          ></ellipse>
                          <path
                            d="M11.75,22.25 L11.75,0.25"
                            id="Line-2"
                            stroke="var(--surface-1)"
                          ></path>
                        </g>
                        <g
                          id="Group-Copy-3"
                          transform="translate(14.500000, 5.000000)"
                        >
                          <ellipse
                            id="Oval-3"
                            fill="var(--surface-1)"
                            transform="translate(7.287431, 22.682819) rotate(-25.000000) translate(-7.287431, -22.682819) "
                            cx="7.28743137"
                            cy="22.6828195"
                            rx="5.6778451"
                            ry="4.22262892"
                          ></ellipse>
                          <path
                            d="M11.75,22.25 L11.75,0.25"
                            id="Line-2"
                            stroke="var(--surface-1)"
                          ></path>
                        </g>
                        <polygon
                          id="Rectangle"
                          fill="var(--surface-1)"
                          points="12 0 26.5 4.49892855 26.5 8.99892855 12 4.5"
                        ></polygon>
                      </g>
                    </g>
                    <path
                      d="M4.50976562,117.884766 L395.490234,117.884766 L395.490234,282.115234 L4.50976562,282.115234 L4.50976562,117.884766 Z M200.5,268 C237.779221,268 268,237.779221 268,200.5 C268,163.220779 237.779221,133 200.5,133 C163.220779,133 133,163.220779 133,200.5 C133,237.779221 163.220779,268 200.5,268 Z"
                      id="cover"
                      fill="var(--surface-0)"
                    ></path>
                  </g>
                </g>
              </svg>

              <h1>Loading Audio</h1>
            </Center>
          </Padding>
        </VerticalCenter>
      </div>
    );
  }

  return <></>;
};

export default AudioLoadingStatus;
