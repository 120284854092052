import VerticalCenter from "../common/components/VerticalCenter";
import { RootState } from "../app/Store";
import { useStore } from "react-redux";
import { selectRecievedWish } from "../features/listenerProfile/select";
import Center from "../common/components/Center";
import { useEffect } from "react";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import { useTranslation } from "react-i18next";
const ReceivedWord = () => {
  const state = useStore().getState() as RootState;
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector("#hello-flower")?.classList.add("grow");
  }, []);

  const wish = selectRecievedWish(state);

  return (
    <>
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/remixed_flower.svg"
            alt="Remixed flower"
            id="hello-flower"
            style={{
              filter: "var(--flower-filter)",
              opacity: 0.5,
              width: "15%",
              height: "15%",
            }}
          />
        </Center>
      </VerticalCenter>
      <Center>
        <Padding>
          <Stack>
            <h1>{t("Someone here sent this to you.")}</h1>
            <h1>
              <em>{wish}</em>
            </h1>
          </Stack>
        </Padding>
      </Center>
    </>
  );
};

export default ReceivedWord;
