import { useTranslation } from "react-i18next";

import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import useSystemAudio from "../common/components/useSystemAudio";
import { useEffect } from "react";

export default function Question2Intro() {
  const { t } = useTranslation();
  const { bell } = useSystemAudio();

  useEffect(() => {
    bell.play();
  }, []);

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h2>{t("Let's learn a little more about you...")}</h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
}
