import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { RootState } from "../app/Store";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import { selectCheckin } from "../features/listenerProfile/select";
import { CheckinOption, CHECKIN_OPTIONS } from "./Checkin";
import AudioFlowerWithCheckoutIcon from "../common/components/AudioFlowerWithCheckoutIcon";
import Stack from "../common/components/Stack";

export default function Checkout() {
  const { t } = useTranslation();
  const state = useStore().getState() as RootState;
  const checkinType = selectCheckin(state);

  const checkin: CheckinOption | undefined = CHECKIN_OPTIONS.find(
    (c) => c.type === checkinType
  );

  return (
    <>
      <Padding>
        <Stack>
          <AudioFlowerWithCheckoutIcon />
          <Center>
            <h2>{t(`${checkin?.description}`)}</h2>
          </Center>
        </Stack>
      </Padding>
    </>
  );
}
