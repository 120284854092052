import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Stack from "../common/components/Stack";
import Padding from "../common/components/Padding";

const InPersonNiceSpendingTimeWithYou = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <Stack>
            <h2>{t("There's a few minutes of music left.")}</h2>
            <h2>
              {t(
                "When you're ready, please make your way to the door. There's no rush, take your time."
              )}
            </h2>
            <h2>
              {t(
                "Knowing when to say goodbye is part of change too, isn't it?"
              )}
            </h2>

            <h2>{t("It was nice spending time with you.")}</h2>
            <h2>{t("Over to you.")}</h2>
          </Stack>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};

export default InPersonNiceSpendingTimeWithYou;
