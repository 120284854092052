import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/Store";
import {
  whatFontSize,
  FontSize,
} from "../features/application/ApplicationReducer";

import Center from "../common/components/Center";
import { Tile, Tiler } from "../common/components/Tiler";
import Padding from "../common/components/Padding";

interface FontSizes {
  label: string;
  cssVariableName: FontSize;
}

const fontSizes: FontSizes[] = [
  {
    label: "Small",
    cssVariableName: "font-size-small",
  },
  {
    label: "Medium",
    cssVariableName: "font-size-medium",
  },
  {
    label: "Large",
    cssVariableName: "font-size-large",
  },
];

const AppearanceText = () => {
  const { t } = useTranslation();
  const fontSize = useSelector(
    (state: RootState) => state.application.fontSize
  );

  const dispatch = useDispatch();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Let's set the scene!")}</h1>
        </Center>
      </Padding>
      <Padding>
        <Center>
          <h2>
            {t(
              "You will be reading text and answering questions on your device throughout Remixed. What size would you like the text to be?"
            )}
          </h2>
        </Center>
      </Padding>
      <Padding>
        <Tiler>
          {fontSizes.map(({ label, cssVariableName }) => (
            <Tile
              label={label}
              isSelected={cssVariableName === fontSize}
              onClick={() =>
                dispatch(whatFontSize(cssVariableName as FontSize))
              }
            >
              <p
                style={{
                  fontSize: `var(--${cssVariableName}`,
                }}
              >
                Aa
              </p>
            </Tile>
          ))}
        </Tiler>
      </Padding>
    </>
  );
};
export default AppearanceText;
