import { ChangeEvent, useState } from "react";
import Padding from "./Padding";
import Stack from "./Stack";

type onChange = (value: number) => void;

interface HorizontalSliderProps {
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  values: number[];
  onChange: onChange;
  defaultValue: number;
}

const HorizontalSlider = (props: HorizontalSliderProps) => {
  const values = props.values;

  const calculatedDefault: number =
    values !== undefined
      ? values.indexOf(props.defaultValue) + 1
      : props.defaultValue;

  if (props.values.length === 0) {
    throw new Error("Nothing specified for `values`");
  }

  if (!props.values.includes(props.defaultValue)) {
    throw new Error(
      "Specified `defaultValue` not contained in provided `values`"
    );
  }

  const [value, setValue] = useState<number>(calculatedDefault);

  return (
    <Padding>
      <Stack>
        <div className="horizontal-labels">
          {props.leftElement && <div>{props.leftElement}</div>}
          {props.rightElement && <div>{props.rightElement}</div>}
        </div>
        <input
          className="slider"
          type="range"
          min={1}
          max={props.values.length}
          defaultValue={props.defaultValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const selectedValue = Number(event.target.value);
            setValue(value);
            if (values) {
              props.onChange(values[selectedValue - 1]);
            } else {
              props.onChange(selectedValue);
            }
          }}
        />
      </Stack>
    </Padding>
  );
};
export default HorizontalSlider;
