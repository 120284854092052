import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  initialState,
  whatEraWhenYoung,
  whatGenreWhenYoung,
  whatMoodWhenYoung,
} from "../features/listenerProfile";
import EraGenreMoodSelect from "../common/components/EraGenreMoodSelect";
import { useTranslation } from "react-i18next";
import Padding from "../common/components/Padding";
import Center from "../common/components/Center";

const Question1WhatMusicYoung = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedEraValue, setSelectedEraValue] = useState(
    initialState.eraYoung
  );
  const [selectedGenreValue, setSelectedGenreValue] = useState(
    initialState.genreYoung
  );

  const [, setSelectedMoodValue] = useState(initialState.moodYoung);

  useEffect(() => {
    setSelectedGenreValue(initialState.genreYoung);
    setSelectedMoodValue(initialState.moodYoung);
  }, [selectedEraValue]);

  useEffect(() => {
    setSelectedMoodValue(initialState.moodYoung);
  }, [selectedGenreValue]);

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Which music did you listen to when you were younger?")}</h1>
        </Center>
      </Padding>
      <Padding>
        <EraGenreMoodSelect
          era={1}
          genre={1}
          mood={1}
          onEraSelect={(eraValue: number) => {
            dispatch(whatEraWhenYoung(eraValue));
            setSelectedEraValue(eraValue);
          }}
          onGenreSelect={(genreValue: number) => {
            dispatch(whatGenreWhenYoung(genreValue));
            setSelectedGenreValue(genreValue);
          }}
          onMoodSelect={(moodValue: number) => {
            dispatch(whatMoodWhenYoung(moodValue));
            setSelectedGenreValue(moodValue);
          }}
        />
      </Padding>
    </>
  );
};
export default Question1WhatMusicYoung;
