import { useTranslation } from "react-i18next";

import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import { useEffect } from "react";

export default function SendWord() {
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector("#goodbye-flower")?.classList.add("shrink");
  }, []);

  return (
    <VerticalCenter>
      <img
        src="assets/images/remixed_flower.svg"
        alt="A monochromatic flower icon with wispy fluffy petals that slowly gets smaller"
        id="goodbye-flower"
        style={{
          filter: "var(--flower-filter)",
          opacity: 0.5,
        }}
      />
      <Center>
        <h1>{t("Bon voyage!")}</h1>
      </Center>
    </VerticalCenter>
  );
}
