import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import VerticalCenter from "../common/components/VerticalCenter";

export default function InPersonNarration2() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <Padding>
          <Stack>
            <h1>{t("Welcome")}</h1>
            <h2>{t("What would help you listen?")}</h2>
          </Stack>
        </Padding>
        <Padding>
          <Stack>
            <h3>{t("Would you like to settle in somewhere?")}</h3>
            <h3>{t("Or would you prefer to move around?")}</h3>
            <h3>{t("You can change anytime")}</h3>
          </Stack>
        </Padding>
        <Padding>
          <Stack>
            <h3>
              {t(
                "If you tend to fidget, play with your seed pod... but don't open it"
              )}
            </h3>
          </Stack>
        </Padding>
      </Center>
    </VerticalCenter>
  );
}
