import { all, put, select, takeEvery } from "redux-saga/effects";
import { AudioName, State as AudioLibraryState } from ".";
import { selectAudioLibrary } from "./select";
import { goToNextStep, goToStepIndex, State as JourneyState } from "../journey";
import { selectJourney } from "../journey/select";
import getPath, { Step } from "../journey/paths/Paths";
import { unloadAudio } from "./AudioLibraryReducer";

import { getAudio } from "./WebAudioAPI";

function* unloadAudioSources() {
  const journey: JourneyState = yield select((state) => selectJourney(state));
  const audioLibrary: AudioLibraryState = yield select((state) =>
    selectAudioLibrary(state)
  );

  const { pathType, stepIndex } = journey;

  const thePathBehind = getPath(pathType).steps.slice(0, stepIndex).reverse();
  const thePathAhead = getPath(pathType).steps.slice(stepIndex).reverse();

  const audioStillRequired = thePathAhead.reduce<AudioName[]>(
    (audioPriority: AudioName[], step: Step) => {
      if (!step) return audioPriority;
      if (!step.acoustics || Object.keys(step.acoustics).length === 0)
        return audioPriority;

      const nextAudio = Object.keys(step.acoustics) as Array<AudioName>;

      return [...audioPriority, ...nextAudio];
    },
    <AudioName[]>[]
  );

  const audioUnloadPriority = thePathBehind.reduce<AudioName[]>(
    (audioPriority: AudioName[], step: Step) => {
      if (!step) return audioPriority;
      if (!step.acoustics || Object.keys(step.acoustics).length === 0)
        return audioPriority;

      const previousAudio = (
        Object.keys(step.acoustics) as Array<AudioName>
      ).filter(
        (audioName) => audioLibrary[audioName as AudioName].status !== "PLAYING"
      );

      return [...audioPriority, ...previousAudio];
    },
    <AudioName[]>[]
  );

  const uniqueAudioStillRequired = [...new Set([...audioStillRequired])];
  const uniqueAudioUnloadPriority = [...new Set([...audioUnloadPriority])];

  const safeAudioToUnload = uniqueAudioUnloadPriority.reduce<AudioName[]>(
    (unloadPriority: AudioName[], audioName: AudioName) => {
      if (uniqueAudioStillRequired.includes(audioName)) {
        return [...unloadPriority];
      }

      return [...unloadPriority, audioName];
    },
    <AudioName[]>[]
  );

  if (safeAudioToUnload.length > 0) {
    safeAudioToUnload.forEach((audioName) => {
      const audio = getAudio(audioLibrary[audioName].sourceUrl);
      if (audio) {
        console.log("Unloading audio....", audioName, audio);
        audio.unload();
      }
    });

    yield put(unloadAudio(safeAudioToUnload));
  }
}

export function* reactions(): unknown {
  yield all([
    yield takeEvery(goToStepIndex.type, unloadAudioSources),
    yield takeEvery(goToNextStep.type, unloadAudioSources),
  ]);
}
