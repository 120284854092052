import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import { sendPasscode, PasscodeResponse } from "../features/PasscodeProxy";
import { goToNextStep } from "../features/journey";
import { whatShowId, setJourneyType } from "../features/listenerProfile";
import { isValidUser } from "../features/questionnaire";

const EnterCode = () => {
  const [code, setCode] = useState<string>("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string>("");

  const processLoginAttempt = (response: PasscodeResponse) => {
    const SHOW_DOESNT_EXIST = "no shows with that id";

    const tzOffsetInMs = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(Date.now() - tzOffsetInMs).toISOString();
    const currentTimeInMs = Date.parse(localISOTime);

    const responseDateInMs = Date.parse(response.result);
    const journeyType = code[0] === "1" ? "IN_PERSON" : "AT_HOME";

    const isValidCode =
      response.result.toLocaleLowerCase() !== SHOW_DOESNT_EXIST;
    const isTooEarly = currentTimeInMs < responseDateInMs;
    const isOnTime = currentTimeInMs >= responseDateInMs;

    if (!isValidCode) {
      setError(
        t("Oops! You may have entered the wrong code. Please try again.")
      );
    } else if (isTooEarly) {
      setError(
        t("Wow, you sure are here early! Come back closer to showtime.")
      );
    } else if (isOnTime) {
      dispatch(whatShowId(response.showID));
      dispatch(isValidUser(true));
      dispatch(setJourneyType(journeyType));
      dispatch(goToNextStep());
    } else {
      setError(t("Something went wrong. Please contact the Remixed team!"));
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      validatePasscode(code);
    }
  }, [code]);

  const validatePasscode = async (passcode: string) => {
    setError("");

    if (passcode.length === 6) {
      const response: PasscodeResponse = await sendPasscode(passcode);
      processLoginAttempt(response);
    } else {
      setError(t("Your passcode is too short."));
    }
  };

  return (
    <VerticalCenter>
      <Padding>
        <div
          style={{
            backgroundImage: "url('assets/images/main_flower_sm.png')",
            backgroundSize: "contain",
            height: "400px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-label="A monochromatic orange flower icon with wispy fluffy
          petals."
        >
          <input
            className="code-input"
            type="tel"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            maxLength={6}
            placeholder="Enter Code"
          />
        </div>
        <Center>
          <p>{error}</p>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};
export default EnterCode;
