import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "../../../app/Store";
import { goToNextStep } from "../JourneyReducer";
import { getPathStepByIndex } from "./Paths";
import { useEffect } from "react";
import { AudioName } from "../../acoustics";
import { useTranslation } from "react-i18next";

import useStepAnimation from "../../../common/components/useStepAnimation";
import FastTravel from "../../../common/components/FastTravel";

import AudioPlaybackProgress from "../../../common/components/AudioPlaybackProgress";
import CountdownToNextStep from "../../../common/components/CountdownToNextStep";

import Padding from "../../../common/components/Padding";
import StickyTopBottom from "../../../common/components/StickyTopBottom";
import LoadingAudio from "./LoadingAudio";
import Button from "../../../common/components/Button";

const PathStepper = () => {
  const dispatch = useDispatch();
  const state = useStore().getState() as RootState;
  const pathType = useSelector((state: RootState) => state.journey.pathType);
  const stepIndex = useSelector((state: RootState) => state.journey.stepIndex);
  const audioLibrary = useSelector((state: RootState) => state.audioLibrary);

  const { t } = useTranslation();

  const step = getPathStepByIndex(pathType, stepIndex);
  const hideBottomSection =
    step?.allowNext.allowNextOn === "COMPONENT_DECIDES" ||
    step.allowNext.config?.showProgressBar === false;

  const { StepAnimationWrapper, animateToNextStep } = useStepAnimation(
    step,
    () => dispatch(goToNextStep())
  );
  const isValidStep = step !== undefined;

  // Run any dispatches the step has defined
  useEffect(() => {
    if (step.dispatchOnEnter) step.dispatchOnEnter(state, dispatch);
  }, [step, state, dispatch]);

  useEffect(() => {
    if (
      step.acoustics &&
      step?.allowNext.allowNextOn === "AUDIO_PLAYBACK_COMPLETE"
    ) {
      const audio =
        step.allowNext.config?.audioCompletion ??
        Object.keys(step.acoustics)[0];

      if (audioLibrary[audio as AudioName].status === "FINISHED_PLAYING") {
        animateToNextStep();
      }
    }
  }, [dispatch, audioLibrary]);

  return (
    <StickyTopBottom
      top={
        <>
          {step && <LoadingAudio step={step} />}
          <FastTravel />
        </>
      }
      middle={
        <>
          {step.component !== undefined && step.component && (
            <StepAnimationWrapper>{step.component} </StepAnimationWrapper>
          )}
        </>
      }
      bottom={
        hideBottomSection ? undefined : (
          <Padding>
            {step?.allowNext.allowNextOn === "MANUAL_CLICK" && (
              <Button
                disabled={!isValidStep}
                onClick={() => {
                  animateToNextStep();
                }}
              >
                {t(`${step.allowNext.config?.footerButtonText ?? "continue"}`)}
              </Button>
            )}
            {step?.allowNext.allowNextOn === "AUDIO_PLAYBACK_COMPLETE" && (
              <AudioPlaybackProgress />
            )}
            {step?.allowNext.allowNextOn === "COUNTDOWN" && (
              <CountdownToNextStep onCountdownExpired={animateToNextStep} />
            )}
          </Padding>
        )
      }
    />
  );
};
export default PathStepper;
