import React from "react";

export type justifyContentType =
  | "start"
  | "center"
  | "end"
  | "space-around"
  | "space-between";

interface SwitcherProps {
  children?: React.ReactNode[];
  justifyContent?: justifyContentType;
}

const Switcher = (props: SwitcherProps) => {
  return (
    <div
      className="switcher"
      style={{
        justifyContent: props.justifyContent ? props.justifyContent : "start",
      }}
    >
      {props.children && props.children.map((child) => <div>{child}</div>)}
    </div>
  );
};
export default Switcher;
