import AudioFlower from "../common/components/AudioFlower";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

export default function Scene3Narration() {
  return (
    <VerticalCenter>
      <Center>
        <AudioFlower animate />
      </Center>
    </VerticalCenter>
  );
}
