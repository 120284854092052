import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/Store";
import { startJourney, takeFirstStep } from "../features/journey";
import {
  ThemeType,
  whatTheme,
} from "../features/application/ApplicationReducer";

import Center from "../common/components/Center";
import Cluster from "../common/components/Cluster";
import VerticalCenter from "../common/components/VerticalCenter";
import { Tile, Tiler } from "../common/components/Tiler";
import Padding from "../common/components/Padding";
import Button from "../common/components/Button";

const THEMES = [
  { label: "Light", img: "sun-dim.png" },
  // { label: "Dim", img: "sun-horizon.png" },
  { label: "Dark", img: "moon.png" },
];

const AppearanceVisual = () => {
  const { t } = useTranslation();
  const theme = useSelector((state: RootState) => state.application.theme);
  const journeyType = useSelector(
    (state: RootState) => state.listenerProfile.journeyType
  );

  const dispatch = useDispatch();

  return (
    <>
      <Padding>
        <Center>
          <h2>
            {t(
              "We recommended selecting dark mode as it will be easier for your eyes in the performance space."
            )}
          </h2>
        </Center>
      </Padding>
      <Padding>
        <Center>
          <h2>{t("If you would prefer a lighter screen, select light.")}</h2>
        </Center>
      </Padding>
      <VerticalCenter>
        <Center>
          <Cluster>
            <Tiler>
              {THEMES.map(({ label, img }) => (
                <Tile
                  label={label}
                  onClick={() => dispatch(whatTheme(label as ThemeType))}
                  isSelected={theme === label}
                >
                  <img className="" src={`/assets/images/${img}`} />
                </Tile>
              ))}
            </Tiler>
          </Cluster>
        </Center>

        <Padding>
          <Button
            onClick={() => {
              dispatch(startJourney(journeyType));
              dispatch(takeFirstStep());
            }}
          >
            {t("next")}
          </Button>
        </Padding>
      </VerticalCenter>
    </>
  );
};
export default AppearanceVisual;
