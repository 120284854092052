import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";

export default function JewelLight2() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h1>
            {t(
              "Let's listen to this song and imagine the possibilities together"
            )}
          </h1>
        </Center>
      </Padding>
    </VerticalCenter>
  );
}
