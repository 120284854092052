import React from "react";
import useSystemAudio from "./useSystemAudio";

interface PillProps {
  onClick: () => void;
  selected: boolean;
  label: string;
  svgComponent?: React.ReactNode;
  ariaLabel?: string;
}

const PillWithIcon = (props: PillProps) => {
  const { onClick, selected, label, svgComponent, ariaLabel } = props;
  console.log(props);

  const { click } = useSystemAudio();

  const hasIcon = svgComponent !== undefined;

  const nonIconPadding = { padding: "10px 20px" };
  const iconPadding = { paddingRight: "20px" };

  return (
    <>
      <div
        role="button"
        className={`pill ${selected ? "pill-selected" : ""} `}
        onClick={() => {
          click.play();
          onClick();
        }}
        style={hasIcon ? { ...iconPadding } : { ...nonIconPadding }}
      >
        {hasIcon && (
          <div className="pill-img" aria-label={ariaLabel}>
            {svgComponent}
          </div>
        )}
        <div>{label}</div>
      </div>
    </>
  );
};

export default PillWithIcon;
