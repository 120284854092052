import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Center from "../common/components/Center";
import HorizontalSlider from "../common/components/HorizontalSlider";
import { changeHowMuch } from "../features/listenerProfile";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";

export default function Question2HowMuchChange() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Have you experienced a lot of change in your life")}</h1>
        </Center>
      </Padding>
      <VerticalCenter>
        <HorizontalSlider
          leftElement={<p>{t("No")}</p>}
          values={[1, 2.33, 3.66, 5]}
          defaultValue={2.33}
          onChange={(value) => dispatch(changeHowMuch(value))}
          rightElement={<p>{t("It never ends")}</p>}
        />
      </VerticalCenter>
    </>
  );
}
