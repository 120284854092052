import { AcousticConfig, getPathStepByIndex } from "../journey/paths/Paths";
import { State as AudioLibraryState } from "../acoustics";
import { PayloadAction } from "@reduxjs/toolkit";
import { eventChannel } from "@redux-saga/core";
import { AudioName, Status } from ".";
import {
  cancelled,
  all,
  delay,
  put,
  select,
  takeEvery,
  take,
  call,
} from "@redux-saga/core/effects";
import {
  finishedAudio,
  playAudio,
  readyToPlayAudio,
  resetToReadyToPlay,
  startAudio,
} from "./AudioLibraryReducer";
import {
  goToNextStep,
  goToStepIndex,
  State as JourneyState,
} from "../journey/JourneyReducer";
import { getAudio } from "./WebAudioAPI";
import { selectAudioLibrary } from "./select";

const FADE_DURATION_SECONDS = 10;

function* stepAudioMightHaveJustBecameReady(action: PayloadAction<AudioName>) {
  const journey: JourneyState = yield select(
    (state): JourneyState => state.journey
  );

  const step = getPathStepByIndex(journey.pathType, journey.stepIndex);

  if (step.acoustics && Object.keys(step.acoustics).includes(action.payload)) {
    yield playWhenReadyToPlay();
  }
}

function* playWhenReadyToPlay() {
  const journey: JourneyState = yield select(
    (state): JourneyState => state.journey
  );
  const audioLibrary: AudioLibraryState = yield select(
    (state): AudioLibraryState => state.audioLibrary
  );

  const step = getPathStepByIndex(journey.pathType, journey.stepIndex);

  if (step.acoustics) {
    const statuses = Object.keys(step.acoustics).map(
      (audioName) => audioLibrary[audioName as AudioName].status
    );

    const isAllStepAudioReady = (status: Status) =>
      [
        "NOT_LOADED",
        "INTERNAL_SEQUENCE_ERROR",
        "LOADING",
        "NO_SOURCE",
      ].includes(status);

    const uniqueStatuses = [...new Set(statuses)];
    if (!uniqueStatuses.some(isAllStepAudioReady)) {
      const stepPlayEventConfig = Object.entries(step.acoustics).reduce(
        (config: PlayEventConfig, [audioName, stepAcousticConfig]) => ({
          ...{
            [audioName]: {
              config: stepAcousticConfig,
              sourceUrl: audioLibrary[audioName as AudioName].sourceUrl,
            },
          },
          ...config,
        }),
        {} as PlayEventConfig
      );

      yield playStepAudio(stepPlayEventConfig);
    }
  }
}

type PlayEventConfig = Record<
  AudioName,
  { config: AcousticConfig; sourceUrl: string }
>;

function attachPlayEventHandlers(playEventConfig: PlayEventConfig) {
  return eventChannel((emitter) => {
    Object.keys(playEventConfig).forEach((audioName) => {
      const { sourceUrl } = playEventConfig[audioName as AudioName];
      const webAudio = getAudio(sourceUrl);
      if (webAudio) {
        webAudio.onPlay((externalId) => {
          emitter({ audioName, event: "ON_PLAY", externalId });
        });

        webAudio.onEnd(() => {
          emitter({ audioName, event: "ON_END", externalId: -1 });
        });
      }
    });

    return () => {
      return;
    };
  });
}

function* playStepAudio(stepPlayEventConfig: PlayEventConfig): unknown {
  // Attach event handlers before triggering play events
  const playEventEmitter = yield call(
    attachPlayEventHandlers,
    stepPlayEventConfig
  );

  // Trigger event i.e. play the audio for the step
  Object.keys(stepPlayEventConfig).forEach((audioName) => {
    const { sourceUrl } = stepPlayEventConfig[audioName as AudioName];
    const webAudio = getAudio(sourceUrl);
    const { volume, fadeInSeconds, secondsUntilFadeIn } =
      stepPlayEventConfig[audioName as AudioName].config;

    if (webAudio) {
      webAudio.volume(volume);

      setTimeout(() => {
        if (!webAudio.isPlaying()) {
          if (fadeInSeconds > -1) {
            webAudio.fadeIn(volume, fadeInSeconds * 1000);
          }
          webAudio.play();
        }
      }, secondsUntilFadeIn * 1000);
    }
  });

  try {
    while (true) {
      // Event has been emitted
      const playEvent = yield take(playEventEmitter);
      const { audioName } = playEvent;
      const audioLibrary: AudioLibraryState = yield select((state) =>
        selectAudioLibrary(state)
      );
      // Using the sourceUrl identify the audio representation that triggered the event
      const webAudioEventOrigin = getAudio(
        audioLibrary[audioName as AudioName].sourceUrl
      );
      if (webAudioEventOrigin) {
        const [eventName, duration] = [
          playEvent.event,
          webAudioEventOrigin.getDuration(),
        ];

        if (eventName === "ON_PLAY") {
          yield put(
            startAudio({
              audioName,
              duration,
              externalId: playEvent.externalId,
            })
          );
        }

        if (eventName === "ON_END") {
          yield put(finishedAudio(audioName));
        }
      }
    }
  } catch (e) {
    console.error("event emmiting loop exeception occurred", e);
  } finally {
    if (yield cancelled()) {
      playEventEmitter.close();
    }
  }
}

function* applyFadeOut(
  action: PayloadAction<{
    audioName: AudioName;
    duration: number;
    externalId: number;
  }>
) {
  // We need to be able to determine when fading has completed:
  // use an eventChannel to `emmit` the `on("fade")` event that way
  // we can set the audio to "READY_TO_PLAY" on fade out or keep it as "PLAYING" if fading in
  const audioLibrary: AudioLibraryState = yield select(
    (state): AudioLibraryState => state.audioLibrary
  );

  const journey: JourneyState = yield select(
    (state): JourneyState => state.journey
  );

  const step = getPathStepByIndex(journey.pathType, journey.stepIndex);
  if (step.acoustics) {
    const acousticConfig = step.acoustics[action.payload.audioName];
    const { secondsUntilFadeOut } = acousticConfig as AcousticConfig;
    const webAudio = getAudio(
      audioLibrary[action.payload.audioName as AudioName].sourceUrl
    );

    if (webAudio) {
      if (secondsUntilFadeOut && secondsUntilFadeOut > -1) {
        yield delay(secondsUntilFadeOut * 1000);
        webAudio.fadeOut(FADE_DURATION_SECONDS * 1000);

        yield delay(FADE_DURATION_SECONDS * 1000);
        webAudio.stop();

        yield put(finishedAudio(action.payload.audioName));
      }
    }
  }
}

function* applyFadeOutForCurrentPlaying(): unknown {
  // We need to be able to determine when fading has completed:
  // use an eventChannel to `emmit` the `on("fade")` event that way
  // we can set the audio to "READY_TO_PLAY" on fade out or keep it as "PLAYING" if fading in
  const journey: JourneyState = yield select(
    (state): JourneyState => state.journey
  );

  const step = getPathStepByIndex(journey.pathType, journey.stepIndex);
  if (step.acoustics) {
    yield all(
      Object.keys(step.acoustics).map((audioName) =>
        applyFadeOut({
          payload: {
            audioName: audioName as AudioName,
            externalId: -1,
            duration: -1,
          },
          type: "",
        })
      )
    );
  }
}

// function* goToNextStepIfRequired(action: PayloadAction<AudioName>) {
// const journey: JourneyState = yield select(
//   (state): JourneyState => state.journey
// );

// const step = getPathStepByIndex(journey.pathType, journey.stepIndex);
// if (
//   step.acoustics &&
//   Object.keys(step.acoustics).length > 0 &&
//   step?.allowNext.allowNextOn === "AUDIO_PLAYBACK_COMPLETE"
// ) {
//   const firstAudioName = Object.keys(step.acoustics).at(0);
//   if (action.payload === firstAudioName) {
//     // yield put(goToNextStep());
//   }
// }
// }

function* updateAudioMetadata(action: PayloadAction<AudioName[]>) {
  const audioLibrary: AudioLibraryState = yield select(
    (state): AudioLibraryState => state.audioLibrary
  );

  action.payload.forEach(function* (audioName) {
    const { sourceUrl } = audioLibrary[audioName];
    const webAudio = getAudio(sourceUrl);

    if (webAudio) {
      yield put(
        startAudio({
          audioName,
          duration: webAudio.getDuration(),
          externalId: -1,
        })
      );
    }
  });
}

function* resetAudioPlaybackComplete() {
  const audioLibrary: AudioLibraryState = yield select(
    (state): AudioLibraryState => state.audioLibrary
  );

  const audioToSetAsReady = Object.keys(audioLibrary).reduce<AudioName[]>(
    (audioNames, audioName) => {
      const audio = audioLibrary[audioName as AudioName];
      return audio.status === "FINISHED_PLAYING"
        ? [...audioNames, audioName as AudioName]
        : [...audioNames];
    },
    []
  );

  if (audioToSetAsReady.length > 0) {
    yield put(resetToReadyToPlay(audioToSetAsReady as AudioName[]));
  }
}

function* stopAudioWhenFastTraveling() {
  const audioLibrary: AudioLibraryState = yield select(
    (state): AudioLibraryState => state.audioLibrary
  );

  const stopAudio = Object.keys(audioLibrary).reduce<AudioName[]>(
    (audioNames, audioName) => {
      const audio = audioLibrary[audioName as AudioName];
      if (audio.status === "PLAYING") {
        return [...audioNames, audioName as AudioName];
      }

      return audioNames;
    },
    []
  );

  yield put(resetToReadyToPlay(stopAudio));

  stopAudio.forEach((audioName) => {
    const { sourceUrl } = audioLibrary[audioName as AudioName];
    const webAudio = getAudio(sourceUrl);
    if (webAudio) {
      webAudio.stop();
    }
  });
}

export function* reactions(): unknown {
  yield all([
    yield takeEvery(readyToPlayAudio.type, stepAudioMightHaveJustBecameReady),
    yield takeEvery(goToStepIndex.type, stopAudioWhenFastTraveling),
    yield takeEvery(
      [goToNextStep.type, goToStepIndex.type],
      playWhenReadyToPlay
    ),
    yield takeEvery(goToNextStep.type, applyFadeOutForCurrentPlaying),
    yield takeEvery(startAudio.type, applyFadeOut),
    yield takeEvery(playAudio.type, updateAudioMetadata),
    yield takeEvery(goToNextStep.type, resetAudioPlaybackComplete),
  ]);
}
