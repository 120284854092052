import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "../app/Store";
import { recievedWish } from "../features/listenerProfile";
import { selectUserId } from "../features/listenerProfile/select";
import * as WishProxy from "../features/WishProxy";

import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import VerticalCenter from "../common/components/VerticalCenter";
import AudioFlower from "../common/components/AudioFlower";
import Button from "../common/components/Button";

export default function WordOfEncouragement() {
  const { t } = useTranslation();
  const state = useStore().getState() as RootState;
  const dispatch = useDispatch();

  const userId = selectUserId(state);
  const [wish, setWish] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const hasText = wish.length > 0;

  if (hasSubmitted) {
    return (
      <VerticalCenter>
        <Center>
          <AudioFlower animate={false} />
          <Padding>
            <h2>{t("Let's package this wish up nicely for you.")}</h2>
          </Padding>
        </Center>
      </VerticalCenter>
    );
  }

  return (
    <Stack>
      <VerticalCenter>
        <Center>
          <AudioFlower animate={false} />
          <Stack>
            <Padding>
              <textarea
                style={{ textAlign: "center" }}
                placeholder={t("Your words of encouragement")}
                onChange={(e) => setWish(e.currentTarget.value)}
              ></textarea>
              <Button
                onClick={() => {
                  if (hasText) {
                    WishProxy.sendWishText(userId, wish).then((response) => {
                      setHasSubmitted(true);
                      dispatch(recievedWish(response.wish));
                    });
                  }
                }}
              >
                {t("Send")}
              </Button>
            </Padding>
          </Stack>
        </Center>
      </VerticalCenter>
    </Stack>
  );
}
