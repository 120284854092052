import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, takeLatest, select, put } from "@redux-saga/core/effects";
import LogRocket from "logrocket";

import {
  FirstQuestionnaireAudioSourceUrls,
  IntroAudioSourceUrls,
  SecondQuestionnaireAudioSourceUrls,
  ThirdQuestionnaireAudioSourceUrls,
} from "../acoustics";
import {
  retrievedIntroAudioSources,
  retrivedFirstQuestionnaireAudioSources,
  retrivedSecondQuestionnaireAudioSources,
  retrivedThirdQuestionnaireAudioSources,
} from "../acoustics/AudioLibraryReducer";
import * as AudioProxy from "../acoustics/AudioProxy";
import { State as JourneyState } from "../journey";
import { State as ListenerProfileState, whatUserId } from "../listenerProfile";
import { State as QuestionnaireState } from "../questionnaire";
import {
  completedQuestionnaireOne,
  completedQuestionnaireTwo,
  completedQuestionnaireThree,
  isValidUser,
} from "./QuestionnaireReducer";
import { getDeviceAndBrowser } from "../userAgentParser/DeviceAnalytics";

export function* makeAPIRequestForQuestionnaireOneAudioSources(): unknown {
  const journey: JourneyState = yield select((state) => state.journey);
  const listenerProfile: ListenerProfileState = yield select(
    (state) => state.listenerProfile
  );
  const {
    userId,
    showId,
    changeType,
    soothingSound,
    eraYoung,
    genreYoung,
    moodYoung,
    excludeTopics,
  } = listenerProfile;
  const musicOld = Number(`${eraYoung}${genreYoung}${moodYoung}`);

  const audioSegments: AudioProxy.FirstListenerProfileSegments = {
    userID: userId,
    showID: showId,
    selection: changeType,
    soothingSound,
    musicOld,
    exclusion: excludeTopics.join(""),
  };

  const audioSources: FirstQuestionnaireAudioSourceUrls = yield call(
    AudioProxy.getFirstAudio,
    audioSegments,
    journey.pathType === "AT_HOME"
  );

  yield put(retrivedFirstQuestionnaireAudioSources(audioSources));
}

export function* makeAPIRequestForQuestionTwoAudioSources(): unknown {
  const journey: JourneyState = yield select((state) => state.journey);
  const listenerProfile: ListenerProfileState = yield select(
    (state) => state.listenerProfile
  );
  const {
    userId,
    showId,
    dependent,
    ignored,
    identityIndependence,
    relationships,
    family,
    changeFeel,
    changeMake,
    excludeTopics,
    wantThreeThings,
    hateThreeThings,
    mostAfraid,
  } = listenerProfile;

  const attachment =
    (dependent * 10 +
      ignored * 7 +
      identityIndependence * 10 +
      relationships * 5 +
      family * 10) /
    42;
  const proChange = (changeFeel + changeMake) / 2;
  const changeImpact = 3.5;

  const changeExperience = 1;
  const hateWantFear = mostAfraid.map((fear, index) => {
    const hate = hateThreeThings[index];
    const want = wantThreeThings[index];
    return fear + hate + want + 1;
  });

  const hateWantFearMaxIndex = hateWantFear.indexOf(Math.max(...hateWantFear));
  const enneagram = hateWantFearMaxIndex === 0 ? 1 : hateWantFearMaxIndex;

  const audioSegments: AudioProxy.SecondListenerProfileSegments = {
    userID: userId,
    showID: showId,
    attachment,
    proChange,
    changeImpact,
    listening: 1,
    exclusion: excludeTopics.join(""),
    enneagram,
    changeExperience,
  };

  const audioSources: SecondQuestionnaireAudioSourceUrls = yield call(
    AudioProxy.getSecondAudio,
    audioSegments,
    journey.pathType === "AT_HOME"
  );

  yield put(retrivedSecondQuestionnaireAudioSources(audioSources));
}

export function* makeAPIRequestForQuestionThreeAudioSources() {
  const journey: JourneyState = yield select((state) => state.journey);
  const listenerProfile: ListenerProfileState = yield select(
    (state) => state.listenerProfile
  );
  const { userId, showId, eraNow, genreNow, moodNow } = listenerProfile;

  const musicNow = Number(`${eraNow}${genreNow}${moodNow}`);
  const audioSegments: AudioProxy.ThridListenerProfileSegments = {
    userID: userId,
    showID: showId,
    musicNew: musicNow,
  };

  const audioSources: ThirdQuestionnaireAudioSourceUrls = yield call(
    AudioProxy.getThridAudio,
    audioSegments,
    journey.pathType === "AT_HOME"
  );

  yield put(retrivedThirdQuestionnaireAudioSources(audioSources));
}

export function* makeAPIRequestForIntroAudioSources() {
  const journey: JourneyState = yield select((state) => state.journey);
  const questionnaireState: QuestionnaireState = yield select(
    (state) => state.questionnaire
  );
  const listenerProfile: ListenerProfileState = yield select(
    (state) => state.listenerProfile
  );
  if (questionnaireState.isValidUser) {
    const deviceAndBrowser = getDeviceAndBrowser();

    const audioSources: IntroAudioSourceUrls = yield call(
      AudioProxy.getIntroAudio,
      {
        showID: `${listenerProfile.showId}`,
        useAtHomeAudio: journey.pathType === "AT_HOME",
        ...deviceAndBrowser,
      } as AudioProxy.IntroAudioSegments
    );
    yield put(retrievedIntroAudioSources(audioSources));
    yield put(whatUserId(audioSources.userID));
  }
}

export function* identifyUser(action: PayloadAction<number>) {
  const journey: JourneyState = yield select((state) => state.journey);

  const userId = action.payload;
  LogRocket.identify(`${userId}`, {
    name: `${userId}`,
    email: `${userId}`,
    journeyType: journey.pathType,
    deployed: process.env.REACT_APP_FIREBASE_DEPLOY ?? "DEV",
  });
}

export function* reactions(): unknown {
  yield all([
    yield takeLatest(
      completedQuestionnaireOne.type,
      makeAPIRequestForQuestionnaireOneAudioSources
    ),
    yield takeLatest(
      completedQuestionnaireTwo.type,
      makeAPIRequestForQuestionTwoAudioSources
    ),
    yield takeLatest(
      completedQuestionnaireThree.type,
      makeAPIRequestForQuestionThreeAudioSources
    ),
    yield takeLatest(isValidUser.type, makeAPIRequestForIntroAudioSources),
    yield takeLatest(whatUserId.type, identifyUser),
  ]);
}
