import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Padding from "../common/components/Padding";
import { hateThreeThings } from "../features/listenerProfile";
import Center from "../common/components/Center";
import PillMultiSelect from "../common/components/PillMultiSelect";

const DISLIKES = [
  "Lack of authenticity",
  "Powerlessness",
  "Unreliability & disloyalty",
  "Being controlled & constrained",
  "Laziness",
  "Boredom & repetition",
  "Selfishness & takers",
  "Lack of drive & ambition",
  "Intrusiveness",
];

export default function Question2HateThreeThings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("What infuriates you the most today? ")}</h1>
        </Center>
        <Center>
          <Padding>
            <pre>{t("SELECT UP TO TWO")}</pre>
          </Padding>
        </Center>
      </Padding>
      <Padding>
        <PillMultiSelect
          maxNumSelectable={2}
          options={DISLIKES.map((dislike) => t(dislike.toLocaleUpperCase()))}
          onClick={(valuesList) => dispatch(hateThreeThings(valuesList))}
        />
      </Padding>
    </>
  );
}
