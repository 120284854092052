import { useStore } from "react-redux";
import CheckinIcon from "./CheckinIcon";
import { selectCheckin } from "../../features/listenerProfile/select";
import { RootState } from "../../app/Store";

const AudioFlowerWithCheckoutIcon = () => {
  const state = useStore().getState() as RootState;
  const checkinAnswer = selectCheckin(state);

  return (
    <div
      style={{
        backgroundImage: "url('assets/images/main_flower_sm.png')",
        backgroundSize: "contain",
        height: "300px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        filter: "var(--surface-2)",
        opacity: 0.5,
      }}
      aria-label="A monochromatic flower icon with wispy fluffy petals"
    >
      <div style={{ width: "100px" }}>
        <CheckinIcon icon={checkinAnswer} includeBorder={true} />
      </div>
    </div>
  );
};

export default AudioFlowerWithCheckoutIcon;
